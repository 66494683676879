/* Colors */
:root {
    --primary-blue: #014d94;
    --secondary-blue: #5894cd;
    --dark-grey: #464646;
    --grey: #aca4a4;
    --medium-grey: #cfcaca;
    --light-grey: #f1f1f2;
}

/* Body */
body {
    font-family: "Lato", sans-serif;
    background: url("./brm_demo_background.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    color: var(--dark-grey);
}

/* Fonts */
h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    color: var(--primary-blue);
}

h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: var(--dark-grey);
}

h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--dark-grey);
}

p {
    margin-bottom: 5px;
}

.text-blue {
    color: var(--primary-blue);
}

.text-alt-blue {
    color: var(--secondary-blue);
}

/* Navbar */
.navbar {
    height: 75px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-bottom: 0px;
}

.nav-item {
    padding: 0px 20px;
    background-color: #fff;
}

.navbar-light .navbar-nav .nav-link {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: var(--dark-grey);
    text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: var(--primary-blue);
    opacity: 0.8;
}

.navbar-light .navbar-nav .nav-link.active {
    font-weight: 600;
    color: var(--primary-blue);
    border-bottom: solid 3px;
}

.navbar-collapse {
    z-index: 1;
}

@media (min-width: 992px) {
    .navbar-collapse {
        height: 67px;
    }
}

.text-brand {
    font-size: 24px;
}

/* General components */
.btn {
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    height: 45px;
}

.btn-primary {
    background-color: var(--primary-blue);
    color: #fff;
    border-color: var(--primary-blue);
    min-width: 150px;
}

.btn-primary:hover {
    background-color: var(--primary-blue);
    opacity: 0.8;
}

.btn-secondary {
    background-color: var(--secondary-blue);
    color: #fff;
    border-color: var(--secondary-blue);
    min-width: 150px;
}

.btn-secondary:hover {
    background-color: var(--secondary-blue);
    border-color: var(--secondary-blue);
    opacity: 0.8;
}

.btn-outline {
    background-color: #fff;
    color: var(--primary-blue);
    border: solid 2px var(--primary-blue);
    min-width: 140px;
}

.btn-outline:hover {
    color: var(--primary-blue);
    background-color: var(--light-grey);
    opacity: 0.8;
}

.btn-sm {
    min-width: 40px;
    padding: 0px 15px;
}

.card {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
}

.content-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.footer {
    background-color: var(--primary-blue);
    opacity: 0.6;
    height: 115px;
    color: #fff;
    display: flex;
}

.form-label {
    margin-bottom: 0.25rem;
}

.table {
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.table-rounded {
    border-radius: 10px;
}

.table > thead {
    background-color: var(--secondary-blue);
    color: #fff;
}

.table-hover > tbody > tr:hover {
    background-color: var(--primary-blue);
    opacity: 0.5;
    color: #fff;
    cursor: pointer;
}

.table-cell-hover td:hover {
    background-color: var(--primary-blue);
    opacity: 0.5;
    color: #fff;
    cursor: pointer;
}

.table-results-count {
    width: 75px;
}

.table > :not(:last-child) > :last-child > * {
    border-bottom-color: var(--light-grey);
}

.table-footer {
    height: 25px;
}

.table-person-link:hover {
    background-color: var(--secondary-blue);
}

.table-no-data {
    cursor: default;
}

/* Datepicker styles */

.datepicker {
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.datepicker-switch {
    color: var(--primary-blue);
}

.datepicker table tr td.active.active {
    background-color: var(--primary-blue);
    background-image: none;
}

.datepicker td {
    padding: 5px;
}

/* Toast styling */
.success-toast {
    background-color: #74b173;
    color: #fff;
}

.success-toast-header {
    background-color: #4a944a;
    color: #fff;
}

.failure-toast {
    background-color: #b05b5b;
    color: #fff;
}

.failure-toast-header {
    background-color: #8d3a3a;
    color: #fff;
}

.btn-close-toast {
    background: none;
    color: #fff;
    border: none;
}

.toast:not(.showing):not(.show) {
    display: none;
}

a.link-button {
    padding-top: 9px;
}
